import {NgModule} from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {NavigationService, routes} from "./_common/services/navigation.service";


@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabledBlocking',
        scrollPositionRestoration: 'enabled',
        scrollOffset: [0, 0],
        anchorScrolling: 'enabled',
        useHash: false,
        enableTracing: false,
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor(
        private readonly router: Router,
        private readonly navService: NavigationService
    ) {

    }
}
