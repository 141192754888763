import {RepositoryService} from "../../services/repository.service";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {OrderModel} from "../models/order.model";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class OrderRepository extends RepositoryService<OrderModel> {

    constructor(client: HttpClient) {
        super(client, OrderModel);
    }

    public getAttachment(data: any): Observable<Blob> {
        return this.client.post(
            environment.notification_path + '/attachments',
            data,
            {
                withCredentials: true,
                responseType: 'blob'
            }
        )
    }
}
