import {PaygreenMode} from "../app/_common/shared/components/paygreen/paygreen.vars";

export const environment = {
    production: false,
    appVersion: 'v8.0.24',
    USERDATA_KEY: 'authdcbed3613785b',
    isMockEnabled: false,
    theater_path: 'https://theater.theater-saas.d.dwm.dev',
    auth_path: 'https://auth.theater-saas.d.dwm.dev',
    account_path: 'https://account.theater-saas.d.dwm.dev',
    shopping_path: 'https://shopping.theater-saas.d.dwm.dev',
    cms_path: 'https://cms.theater-saas.d.dwm.dev',
    notification_path: 'https://notification.theater-saas.d.dwm.dev',
    appThemeName: 'Théâtre',
    // origin: 'http://localhost:4200',
    origin: 'https://preprod.theater-saas.d.dwm.dev',
    paygreen_env: PaygreenMode.sandbox,
    paygreen_pk: 'pk_42747ffc975f4bb1b2fe33b640393bc8',
    turnstile_key: '0x4AAAAAAAFFGnWEg3XFUILf', // domain fsk
    // turnstile_key: '0x4AAAAAAADlL-7Py74qBuHD', // domain localhost
    show_cart_discount_code_form: true
};
