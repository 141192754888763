import {Api, Column} from "../../decorators/api.decorator";
import {environment} from "../../../../../environments/environment";

@Api({
    basePath: 'gift-codes',
    baseURL: environment.shopping_path
})
export class CodeModel {
    @Column()
    id: string = '';
    @Column()
    code: string = '';
    @Column()
    usedAt: string = '';
}
