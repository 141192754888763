import {Api, ApiRoute, Column} from "../decorators/api.decorator";
import {environment} from "../../../../environments/environment";

@Api({
    basePath: 'subscriptions',
    baseURL: environment.shopping_path
})
@ApiRoute('NB_USE', '')
export class SubscriptionModel {
    @Column()
    id: string = '';

    @Column()
    code: string = '';

    @Column()
    start: string = '';

    @Column()
    stop: string = '';

    @Column()
    priceGroup: string = '';
}
