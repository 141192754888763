import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
import {Router} from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'theater-saas-front';

    constructor(
        @Inject(PLATFORM_ID) private platformId: {},
        public router: Router,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.router.initialNavigation();
        }
    }
}
