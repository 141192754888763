import {Inject, Injectable, NgZone, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import {isPlatformServer} from '@angular/common';
// @ts-ignore
import * as memoryCache from 'memory-cache';
import {IDictionary} from "../interfaces/IDictionary";

@Injectable({
    providedIn: 'root',
})
export class CacheService {

    private cache: IDictionary = {};
    private stateKeys: IDictionary = {};

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private ngZone: NgZone,
        private transferState: TransferState,
    ) {
    }

    public set(key: string, value: any, observable: boolean = false) {
        if (observable) {
            if (this.cache.hasOwnProperty('__subject.' + key)) {
                this.cache['__subject.' + key].next(value);
            } else {
                this.cache['__subject.' + key] = new BehaviorSubject<any>(value);
                this.cache[key] = this.cache['__subject.' + key].asObservable();
            }
        } else {
            this.cache[key] = value;
        }
    }

    public get(key: string) {
        if (this.cache.hasOwnProperty(key)) {
            return this.cache[key];
        }
        return null;
    }

    public remove(key: string) {
        if (this.cache.hasOwnProperty(key)) {
            delete this.cache[key];
        }
    }

    public has(key: string) {
        return this.cache.hasOwnProperty(key);
    }

    public hasStateKey<T>(key: string) {
        this.initStateKey<T>(key);
        if (isPlatformServer(this.platformId)) {
            return memoryCache.get(key) !== null || this.transferState.hasKey(this.stateKeys[key]);
        }
        return this.transferState.hasKey(this.stateKeys[key]);
    }

    public saveStateKey(key: string, value: any) {
        if (!this.stateKeys.hasOwnProperty(key)) {
            return;
        }
        if (isPlatformServer(this.platformId)) {
            this.transferState.set(this.stateKeys[key], value);
            this.ngZone.runOutsideAngular(() => {
                memoryCache.put(key, value, 300);
            });
        }
    }

    public getStateKey<T>(key: string, defaultValue: T) {
        this.initStateKey<T>(key);
        if (isPlatformServer(this.platformId)) {
            const value = memoryCache.get(key);
            this.transferState.set(this.stateKeys[key], value);
            return value;
        }
        const data = this.transferState.get<T>(this.stateKeys[key], defaultValue);
        this.transferState.remove(this.stateKeys[key]);
        delete this.stateKeys[key];
        return data;
    }

    public initStateKey<T>(key: string) {
        if (!this.stateKeys.hasOwnProperty(key)) {
            this.stateKeys[key] = makeStateKey<T>(key);
        }
    }
}
