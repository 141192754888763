import {APP_INITIALIZER, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TurnstileService} from "./turnstile.service";
import {TURNSTILE_OPTIONS, TurnstileConfig} from "./turnstile.var";
import {TurnstileComponent} from './turnstile.component';


function loadTurnstile(turnstileService: TurnstileService) {
  return () => {
    return turnstileService.loadJs()
  };
}

@NgModule({
  declarations: [
    TurnstileComponent
  ],
  exports: [
    TurnstileComponent
  ],
  imports: [
    CommonModule
  ]
})
export class TurnstileModule {

  static forRoot(config: TurnstileConfig): ModuleWithProviders<TurnstileModule> {
    return {
      ngModule: TurnstileModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: loadTurnstile,
          multi: true,
          deps: [TurnstileService],
        },
        {
          provide: TURNSTILE_OPTIONS,
          useValue: config,
        },
      ],
    };
  }
}
