import {GeojonModel} from './geojon.model';
import {Column} from "../decorators/api.decorator";

export class PlaceResponseModel {
    @Column()
    fullName: string = '';
    @Column()
    housenumber: string = '';
    @Column()
    city: string = '';
    @Column()
    type: string = '';
    @Column()
    extension: string = '';
    @Column()
    postalcode: string = '';
    @Column()
    road: string = '';
    @Column()
    geoJson: GeojonModel = new GeojonModel();
    @Column()
    department: string = '';
    @Column()
    country: string = '';
    @Column()
    region: string = '';
    @Column()
    custom: boolean = false;
}
