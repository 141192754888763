import {RepositoryService} from "../../../services/repository.service";
import {RowModel} from "../../models/cart/row.model";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class RowRepository extends RepositoryService<RowModel> {

    constructor(client: HttpClient) {
        super(client, RowModel);
    }
}
