import {CriteriaInterface} from "../interfaces/criteria.interface";
import {map, Observable, of} from "rxjs";
import {HttpClient, HttpEvent, HttpParams} from "@angular/common/http";
import {DictionnaryInterface} from "../interfaces/dictionnary.interface";
import {ConfigRouteInterface} from "../interfaces/config.route.interface";
import {isBlank} from "../shared/utils/utils";
import {CollectionResponse} from "../interfaces/bridge-response.interface";

export class RepositoryService<T> {

    public readonly meta: any = {};

    constructor(public client: HttpClient, private type: (new() => T)) {
        this.meta = new this.type;
    }

    public findBy<T>(criteria: CriteriaInterface | null = null, configRoute: ConfigRouteInterface | null = null, withCredentials: boolean = true, root: string = 'members'): Observable<CollectionResponse<T>> {
        let pathToCall = this.genPathToCall(configRoute);
        const options: { withCredentials: boolean, params?: HttpParams } = {withCredentials: withCredentials};
        if (criteria !== null && typeof criteria === 'object') {
            let queryParams = new HttpParams();
            for (const paramsKey in criteria) {
                // @ts-ignore
                if (criteria.hasOwnProperty(paramsKey) && criteria[paramsKey] !== '' && criteria[paramsKey] !== null) {
                    // @ts-ignore
                    queryParams = queryParams.set(paramsKey, criteria[paramsKey]);
                }
            }
            options.params = queryParams;
        }

        return this.client.get<any>(pathToCall, options)
            .pipe(
                map((data) => {
                        return {
                            // @ts-ignore
                            members: data[root].map<T>(row => this.populate(this.type, row)),
                            pagination: data.pagination
                        };
                    }
                )
            );
    }

    public find<T>(id: string | number, configRoute: ConfigRouteInterface | null = null, withCredentials: boolean = true): Observable<T | null> {
        // @ts-ignore
        const pathToCall = this.genPathToCall(configRoute) + '/' + id;
        const options: { withCredentials: boolean, params?: HttpParams } = {withCredentials: withCredentials};
        return this.client.get<any>(pathToCall, options)
            .pipe(map((data) => this.populate(this.type, data)));
    }

    public findOneBy<T>(criteria: CriteriaInterface | null = null, configRoute: ConfigRouteInterface | null = null, withCredentials: boolean = true, raw: boolean = false): Observable<T | null> {
        // @ts-ignore
        const pathToCall = this.genPathToCall(configRoute);
        const options: { withCredentials: boolean, params?: HttpParams } = {withCredentials: withCredentials};
        if (criteria !== null && typeof criteria === 'object') {
            let queryParams = new HttpParams();
            for (const paramsKey in criteria) {
                // @ts-ignore
                if (criteria.hasOwnProperty(paramsKey) && criteria[paramsKey] !== '' && criteria[paramsKey] !== null) {
                    // @ts-ignore
                    queryParams = queryParams.set(paramsKey, criteria[paramsKey]);
                }
            }
            options.params = queryParams;
        }
        return this.client.get<any>(pathToCall, options)
            .pipe(map((data) => raw ? data : this.populate(this.type, data)));
    }

    public persist<T>(newData: T, oldData: T | null, configRoute: ConfigRouteInterface | null = null, withCredentials: boolean = true): Observable<T | null> {
        oldData ||= this.meta;
        newData = Object.assign(Object.create(Object.getPrototypeOf(oldData)), newData);
        // @ts-ignore
        const isNew = isBlank(oldData[oldData.__primary]);
        let pathToCall = this.genPathToCall(configRoute);
        if (isNew) {
            const diffs = this.diff(oldData, newData);
            return this.client.post<T>(pathToCall, diffs, {withCredentials: withCredentials})
                .pipe(map((data) => this.populate(this.type, data)));
        }
        // @ts-ignore
        pathToCall += '/' + oldData[oldData.__primary];

        const diff = this.diff(oldData, newData);
        if (Object.keys(diff).length === 0) {
            return of<T | null>(oldData);
        }
        return this.client.put<T>(pathToCall, diff, {withCredentials: withCredentials})
            .pipe(map((data) => this.populate(this.type, data)));
    }

    public remove<T>(obj: T, configRoute: ConfigRouteInterface | null = null, withCredentials: boolean = true): Observable<void> {
        // @ts-ignore
        const pathToCall = this.genPathToCall(configRoute) + '/' + obj[this.meta.__primary];
        return this.client.delete<void>(pathToCall, {withCredentials: withCredentials});
    }

    public populate<T>(type: any, data: any): T | null {
        const instance = new type;
        if (instance.__fieldName === undefined) {
            return instance;
        }
        if (isBlank(data)) {
            return null;
        }
        const parseData: DictionnaryInterface = {};
        Object.getOwnPropertyNames(data).forEach((key) => {
            if (instance.__fieldName.hasOwnProperty(key)) {
                if (typeof instance.__fieldName[key].type !== 'string') {
                    const currentType = instance.__fieldName[key].type;
                    if (Array.isArray(data[key])) {
                        parseData[instance.__fieldName[key].fieldName] = data[key].map((value: any) => this.populate(currentType, value));
                    } else {
                        parseData[instance.__fieldName[key].fieldName] = this.populate(currentType, data[key])
                    }
                } else {
                    parseData[instance.__fieldName[key].fieldName] = data[key];
                }
            }
        });
        return Object.assign(instance, parseData as any);
    }

    public post<T>(body: any, configRoute: ConfigRouteInterface | null = null, withCredentials: boolean = true, raw: boolean = false): Observable<T | null> {
        const pathToCall = this.genPathToCall(configRoute);
        return this.client.post<T>(pathToCall, body, {withCredentials: withCredentials})
            .pipe(map((data) => (raw ? data : this.populate(this.type, data))));
    }

    public patch<T>(body: any, configRoute: ConfigRouteInterface | null = null, withCredentials: boolean = true, raw: boolean = false): Observable<T | null> {
        const pathToCall = this.genPathToCall(configRoute);
        return this.client.patch<T>(pathToCall, body, {withCredentials: withCredentials})
            .pipe(map((data) => (raw ? data : this.populate(this.type, data))));
    }

    public put<T>(body: any, configRoute: ConfigRouteInterface, withCredentials: boolean = true, raw: boolean = false): Observable<T | null> {
        const pathToCall = this.genPathToCall(configRoute);
        return this.client.put<T>(pathToCall, body, {withCredentials: withCredentials})
            .pipe(map((data) => (raw ? data : this.populate(this.type, data))));
    }

    public upload<T>(body: any, configRoute: ConfigRouteInterface | null, withCredentials: boolean = true): Observable<HttpEvent<T>> {
        const pathToCall = this.genPathToCall(configRoute);
        return this.client.post<T>(pathToCall, body,
            {
                withCredentials: withCredentials,
                reportProgress: true,
                observe: 'events'
            }
        );
    }

    public delete<T>(configRoute: ConfigRouteInterface, body: any | null = null, withCredentials: boolean = true): Observable<void> {
        const pathToCall = this.genPathToCall(configRoute);
        const options: { withCredentials: boolean, params?: HttpParams } = {withCredentials: withCredentials};
        if (body !== null) {
            options.params = body;
        }
        return this.client.delete<void>(pathToCall, options);
    }

    public diff(src: any, tgt: any) {
        let diffs: any = {};
        Object.getOwnPropertyNames(tgt).forEach((key) => {
            if (key.match(/^_/g) !== null) {
                key = key.substring(1);
            }
            if ((this.meta.__ignoreList || []).includes(key) || isBlank(this.meta.__fieldName[key])) {
                return;
            }
            if ((this.meta.__always || []).includes(key) || isBlank(src)) {
                diffs[key] = tgt[key];
                return;
            }
            if (Array.isArray(src[key])) {
                tgt[key].forEach((value: any, index: any) => {
                    if (typeof this.meta.__fieldName[key].type === 'function') {
                        let currentType = new this.meta.__fieldName[key].type;
                        let isApiObject = !isBlank(currentType.__primary);
                        if (isApiObject) {
                            let srcChildPrimary = isBlank(src[key][index]) || !src[key][index].hasOwnProperty(currentType.__primary) ? null : src[key][index][currentType.__primary];
                            let tgtChildPrimary = isBlank(tgt[key][index]) || !tgt[key][index].hasOwnProperty(currentType.__primary) ? null : tgt[key][index][currentType.__primary];
                            if (srcChildPrimary !== tgtChildPrimary) {
                                diffs[key].push(tgtChildPrimary);
                            }
                        } else {
                            let testDiff = this.diff(src[key][index], value);
                            if (Object.keys(testDiff).length > 0) {
                                if (diffs[key] === undefined) {
                                    diffs[key] = [];
                                }
                                diffs[key].push(value);
                            }
                        }
                    } else if (src[key][index] !== value) {
                        diffs[key].push(tgt[key]);
                        return;
                    }
                })
            } else if (typeof this.meta.__fieldName[key].type === 'function') {
                let currentType = new this.meta.__fieldName[key].type;
                let isApiObject = !isBlank(currentType.__primary);
                if (isApiObject) {
                    let srcChildPrimary = isBlank(src[key]) || !src[key].hasOwnProperty(currentType.__primary) ? null : src[key][currentType.__primary];
                    let tgtChildPrimary = isBlank(tgt[key]) || !tgt[key].hasOwnProperty(currentType.__primary) ? null : tgt[key][currentType.__primary];
                    if (srcChildPrimary !== tgtChildPrimary) {
                        diffs[key] = tgtChildPrimary;
                    }
                } else {
                    let testDiff = this.diff(src[key], tgt[key]);
                    if (Object.keys(testDiff).length > 0) {
                        diffs[key] = testDiff;
                    }
                }
            } else if (src[key] !== tgt[key]) {
                diffs[key] = tgt[key];
            }
        });
        return diffs;
    }

    protected genPathToCall(configRoute: ConfigRouteInterface | null = null) {
        let pathToCall = this.meta.__baseURL;
        if (configRoute === null) {
            // @ts-ignore
            return pathToCall + '/' + this.meta.__basePath;
        }
        let currentPath = isBlank(this.meta.__route[configRoute.name]) ? configRoute.name : this.meta.__route[configRoute.name].path;
        if (!isBlank(this.meta.__route[configRoute.name]) && !isBlank(this.meta.__route[configRoute.name].base_url)) {
            pathToCall = this.meta.__route[configRoute.name].base_url;
        }
        Object.getOwnPropertyNames(configRoute.queryParams).forEach((key) => {
            currentPath = currentPath.replace('{' + key + '}', configRoute.queryParams[key]);
        });
        return pathToCall + currentPath;
    }
}
