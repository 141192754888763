import {Api, ApiRoute, Column} from "../decorators/api.decorator";
import {environment} from "../../../../environments/environment";

@Api({
    baseURL: environment.account_path,
    basePath: 'accounts'
})
@ApiRoute('POPUP', '/popup', environment.cms_path)
@ApiRoute('NEWSLETTERS', '/newsletters')
export class AccountModel {
    @Column()
    id: string = '';
    @Column()
    companyName: string = '';
    @Column()
    tradeName: string = '';
    @Column()
    code: string = '';
    @Column()
    rcs: string = '';
    @Column()
    address: string = '';
    @Column()
    siret: string = '';
    @Column()
    apeCode: string = '';
    @Column()
    iban: string = '';
    @Column()
    bic: string = '';
    @Column()
    phone: string = '';
    @Column()
    url: string = '';
    @Column()
    giftBoxDefaultOn: boolean = false;

    @Column()
    popupTitle: string = '';

    @Column()
    popupBody: string = '';

    @Column()
    popupActif: boolean = false;

    @Column()
    popupVersion: string = '';
}
