import {roleHierarchy} from './roles';
import {Injectable} from '@angular/core';

export const mapRole: any = {};

@Injectable({
    providedIn: 'root',
})
export class RoleLoader {

    loadRoles() {
        return new Promise((resolve => {
                for (let [main, roles] of Object.entries(roleHierarchy)) {
                    roles ||= [];
                    mapRole[main] = [...roles];
                    let visited: string[] = [];
                    let additionalRoles = [...roles];
                    let role: any = null;
                    while (role = additionalRoles.shift()) {
                        if (!roleHierarchy[role]) {
                            continue;
                        }
                        visited.push(role);
                        roleHierarchy[role]!.forEach((roleToAdd: string) => {
                                mapRole[main].push(roleToAdd);
                            }
                        );
                        let differences = roleHierarchy[role]!.filter(x => !visited.includes(x));
                        differences.forEach((additionalRole) => {
                            additionalRoles.push(additionalRole);
                        });
                    }
                    mapRole[main] = mapRole[main].filter((el: any, index: any, arr: any) => {
                        return index == arr.indexOf(el);
                    });
                }
                resolve(mapRole);
            })
        );
    }
}
