import {ItemModel} from './menu/item.model';
import {Always, Api, ApiRoute, Column} from "../decorators/api.decorator";
import {environment} from "../../../../environments/environment";
import {AccountModel} from "./account.model";

@Api({
    basePath: 'menus',
    baseURL: environment.cms_path
})
@ApiRoute('BY_SLUG', '/menus/slug/{slug}')
export class MenuModel {
    @Column()
    id: string = '';
    @Column('account', AccountModel)
    account: AccountModel = new AccountModel();
    @Column()
    name: string | null = null;
    @Column('children', ItemModel)
    @Always()
    children: ItemModel[] = [];
    @Column()
    slug: string | null = null;
}
