import {Api, ApiRoute, Column, Ignore, ToFloat} from "../decorators/api.decorator";
import {RowModel} from "./order/row.model";
import {AccountModel} from "./account.model";
import {PaymentModel} from "./order/payment.model";
import {environment} from "../../../../environments/environment";

@Api({
    basePath: 'orders',
    baseURL: environment.shopping_path
})
@ApiRoute('REFUND', '/orders/{ido}/refund')
@ApiRoute('CART_TO_ORDER', '/carts/{idc}/orders')
@ApiRoute('PAY', '/orders/{ido}/payment-intent/{type}')
@ApiRoute('ATTACHMENTS', '/orders/{ido}/attachments/{type}')
@ApiRoute('ME', '/me/orders')
export class OrderModel {

    constructor() {
    }

    @Column()
    id: string = '';

    @Ignore()
    @Column('account', AccountModel)
    account: AccountModel = new AccountModel();
    @Column()
    user: any = null;
    @Column()
    userInfo: any = null;
    @Column()
    code: string = '';

    @Column()
    status: string = '';

    @Column()
    email: string = '';
    @Column()
    @ToFloat()
    total: number = 0;
    @Column()
    @ToFloat()
    totalTax: number = 0;
    @Column()
    @ToFloat()
    totalIncTax: number = 0;
    @Column('rows', RowModel)
    rows: RowModel[] = [];

    @Column()
    createdAt: string = '';

    @Column('payments', PaymentModel)
    payments: PaymentModel[] = []

    @Ignore()
    loading: boolean = false;
}
