import {isPlatformServer} from "@angular/common";
import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {TURNSTILE_OPTIONS, TurnstileConfig} from "./turnstile.var";
import {isBlank} from "../../utils/utils";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class TurnstileService {

  public tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public token$: Observable<string | null> = this.tokenSubject.asObservable();
  private turnstile = {
    render: (container: string | HTMLElement, params: any): string => {
      return '';
    },
    remove: (widgetId: string) => {
    },
    reset: (widgetId: string) => {
    },
  };
  private widgetId: string | null = null;


  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Inject(TURNSTILE_OPTIONS) public readonly config: TurnstileConfig) {
  }

  loadJs(): Promise<any> {
    if (isPlatformServer(this.platformId)) {
      return new Promise<any>((resolve) => resolve({loaded: false}));
    }
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
      script.onload = () => {
        this.turnstile = window.turnstile || {};
        resolve({loaded: true});
      };
      script.onerror = (error: any) => reject({error: error, loaded: false});
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  render(el: HTMLElement) {
    this.widgetId = this.turnstile.render(
      el,
      {
        sitekey: this.config.publicKey,
        theme: this.config.theme,
        callback: (token: any) => {
          this.tokenSubject.next(token);
        },
        'error-callback': () => {
          this.tokenSubject.next(null);
        }
      }
    );
  }

  reset() {
    if (!isBlank(this.widgetId)) {
      this.turnstile.reset(this.widgetId);
      this.widgetId = null;
    }
    this.tokenSubject.next(null);
  }

  remove() {
    if (!isBlank(this.widgetId)) {
      this.turnstile.remove(this.widgetId);
      this.widgetId = null;
    }
    this.tokenSubject.next(null);
  }
}
