import {Injectable} from '@angular/core';
import {BehaviorSubject, filter, Observable} from 'rxjs';
import {ViolationInterface} from '../interfaces/violation.interface';
import {ToastrService} from "ngx-toastr";
import {NavigationEnd, Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    public messageSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    public message$: Observable<string | null> = this.messageSubject.asObservable();

    public violationsSubject: BehaviorSubject<ViolationInterface | null> = new BehaviorSubject<ViolationInterface | null>(null);
    public violations$: Observable<ViolationInterface | null> = this.violationsSubject.asObservable();

    public showToastrContext: boolean = true;

    constructor(
        private readonly toastr: ToastrService,
        private readonly router: Router
    ) {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event) => {
            this.clear();
        });
    }

    async throwError(err: any) {
        window.scroll(0, 0);
        let errorDetail = err.error;
        if (err.error instanceof Blob) {
            errorDetail = JSON.parse(await err.error.text());
        }
        let msgTitle = 'Oups une erreur est survenue';
        let msgDetail = errorDetail.detail || errorDetail.message || err.message;
        if (err?.error?.trace?.detail) {
            msgTitle = errorDetail.detail || errorDetail.message || err.message;
            msgDetail = err.error.trace.detail;
        }
        if (this.showToastrContext) {
            this.toastr.error(
                msgDetail,
                msgTitle,
                {
                    newestOnTop: true,
                    progressBar: true,
                    positionClass: 'toast-top-center',
                    enableHtml: true
                }
            );
        }
        this.messageSubject.next(msgDetail);
        if (err.error?.trace?.violations) {
            const violations: ViolationInterface = {};
            errorDetail.trace.violations.forEach((value: any) => {
                if (!violations.hasOwnProperty(value.propertyPath)) {
                    violations[value.propertyPath] = [];
                }
                violations[value.propertyPath]!.push(value.title!);
            });
            this.violationsSubject.next(violations);
        }
    }

    public clear() {
        this.messageSubject.next(null);
        this.violationsSubject.next(null);
        this.showToastrContext = true;
    }
}
