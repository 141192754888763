import {Component, Input, OnInit} from '@angular/core';
import {ItemModel} from '../../models/menu/item.model';
import {Router} from "@angular/router";

@Component({
    selector: 'app-menu-parts',
    templateUrl: './menu-parts.component.html',
    styleUrls: ['./menu-parts.component.scss']
})
export class MenuPartsComponent implements OnInit {

    @Input() items: ItemModel[] = [];
    @Input() depth: number = 1;
    @Input() needHover: boolean = true;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    isInternal(link: string | null): boolean {
        if (link === null) {
            return true;
        }
        return link.match(/^\//g) !== null;
    }

    toggleMenu(e: any, menuLink: HTMLElement, submenu: HTMLElement) {
        e.preventDefault();
        if (!this.needHover) {
            return this.router.navigateByUrl(menuLink.getAttribute('href')!);
        }
        if (!submenu.classList.contains('submenu')) {
            return '';
        }
        submenu.classList.toggle('submenu-open');
        return '';
    }
}
