import {RepositoryService} from "../../services/repository.service";
import {AccountModel} from "../models/account.model";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AccountRepository extends RepositoryService<AccountModel> {
    constructor(client: HttpClient) {
        super(client, AccountModel);
    }
}
