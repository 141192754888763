import {RepositoryService} from "../../services/repository.service";
import {SeoModel} from "../models/seo.model";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class SeoRepository extends RepositoryService<SeoModel> {

    constructor(client: HttpClient) {
        super(client, SeoModel);
    }
}
