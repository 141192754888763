import {Api, ApiRoute, Column} from "../decorators/api.decorator";
import {environment} from "../../../../environments/environment";
import {AccountModel} from "./account.model";

@Api({
    basePath: 'seo',
    baseURL: environment.cms_path
})
@ApiRoute('BY_REF', '/ref/{rid}/seo')
@ApiRoute('BY_URL', '/seo/url{url}')
@ApiRoute('SITEMAP', '/accounts/{aid}/seo/sitemap')
@ApiRoute('ROUTING', '/seo/routing')
export class SeoModel {
    @Column()
    id: string | null = null;
    @Column('account', AccountModel)
    account: AccountModel = new AccountModel();
    @Column()
    ref: string | null = null;
    @Column()
    fullUrl: string | null = null;
    @Column()
    type: string = 'page';
    @Column()
    url: string | null = null;
    @Column('parent', SeoModel)
    parent: SeoModel | null = null;
    @Column('children', SeoModel)
    children: SeoModel[] = [];
    @Column()
    meta: any = {}
}
