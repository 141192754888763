import {RowModel} from "./cart/row.model";
import {Api, ApiRoute, Column, ToFloat} from "../decorators/api.decorator";
import {environment} from "../../../../environments/environment";

@Api({
    basePath: 'carts',
    baseURL: environment.shopping_path
})
@ApiRoute('ADD_PRODUCT', '/carts/{idc}/products/{type}')
@ApiRoute('CREATE', '/carts')
@ApiRoute('EDIT_ROW', '/carts/{idc}/rows/{idr}')
@ApiRoute('ADD_CODE', '/carts/{idc}/gift-code')
@ApiRoute('DEL_CODE', '/carts/{idc}/rows/{idr}/gift-code')
export class CartModel {

    @Column()
    id: string = '';
    @Column()
    user: any = null;
    @Column()
    userInfo: any = null;
    @Column()
    email: string = '';
    @Column()
    @ToFloat()
    total: number = 0;
    @Column()
    @ToFloat()
    totalTax: number = 0;
    @Column()
    @ToFloat()
    totalIncTax: number = 0;
    @Column()
    expiredAt: string = '';
    @Column('rows', RowModel)
    rows: RowModel[] = [];
}
