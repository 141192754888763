import {RepositoryService} from "../../../services/repository.service";
import {UserModel} from "../models/user.model";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class UserRepository extends RepositoryService<UserModel> {

    constructor(client: HttpClient) {
        super(client, UserModel);
    }
}
