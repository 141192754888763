import {ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {CartService} from '../../../_common/services/cart.service';
import {fromEvent, Subscription} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import Timeout = NodeJS.Timeout;

@Component({
    selector: 'app-countdown',
    templateUrl: './countdown.component.html',
    styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnDestroy {
    // on récupère un tableau des initiales des valeurs à afficher
    // ex : ['d', 'h', 'm', 's'] || ['m', 's']
    @Input() display: string[] = ['d', 'h', 'm', 's'];
    @Input() displayLetters = true;
    @Input() isCartCountDown = false;

    public timerdate: number;
    public now: number;
    public dashOffset = 0;
    private setInterval: Timeout | null = null;
    private diff: number;
    private maxDashOffset = 66;
    private initDiffOffset = 0;
    private unsubscribe: Subscription = new Subscription();
    private expiredCartTimeOut: Timeout | null = null;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private readonly cartService: CartService,
        private readonly toastService: ToastrService,
        private readonly cdr: ChangeDetectorRef
    ) {
        this.unsubscribe.add(
            fromEvent(this.document, 'visibilitychange')
                .subscribe(() => {
                    this.date = this.cartService.cart.expiredAt;
                    this.init();
                    this.check();
                })
        );
        if (this.setInterval) {
            clearInterval(this.setInterval);
        }
        this.setInterval = setInterval(() => {
            this.check();
        }, 1000);
    }

    private _date: string;

    @Input()
    get date(): string {
        return this._date;
    }

    set date(value: string) {
        const oldValue = this._date;
        this._date = value;
        if (oldValue !== this._date) {
            this.init();
        }
    }

    get secondsValue(): number {
        return (this.timerdate - this.now) % 60;
    }

    get seconds() {
        const secondsValue: number = this.secondsValue;
        let seconds = Math.abs(secondsValue) >= 0 && Math.abs(secondsValue) < 10 ? '0' + Math.abs(secondsValue) : Math.abs(secondsValue);
        if (secondsValue <= 0 && this.display.indexOf('m') === -1) {
            seconds = '-' + seconds;
        }
        return seconds;
    }

    get minutesValue(): number {
        return Math.trunc((this.timerdate - this.now) / 60) % 60;
    }

    get minutes() {
        const minutesValue: number = this.minutesValue;
        let minutes = Math.abs(minutesValue) >= 0 && Math.abs(minutesValue) < 10 ? '0' + Math.abs(minutesValue) : Math.abs(minutesValue);
        if (minutesValue <= 0 && this.display.indexOf('h') === -1 && this.secondsValue < 0) {
            minutes = '-' + minutes;
        }
        return minutes;
    }

    get hoursValue(): number {
        return Math.trunc((this.timerdate - this.now) / 60 / 60) % 24;
    }

    get hours() {
        const hoursValue: number = this.hoursValue;
        let hours = Math.abs(hoursValue) >= 0 && Math.abs(hoursValue) < 10 ? '0' + Math.abs(hoursValue) : Math.abs(hoursValue);
        if (hoursValue <= 0 && this.display.indexOf('d') === -1 && this.minutesValue < 0) {
            hours = '-' + hours;
        }
        return hours;
    }

    get daysValue(): number {
        return Math.trunc((this.timerdate - this.now) / 60 / 60 / 24);
    }

    get days() {
        const daysValue: number = this.daysValue;
        let days = Math.abs(daysValue) >= 0 && Math.abs(daysValue) < 10 ? '0' + Math.abs(daysValue) : Math.abs(daysValue);
        if (daysValue <= 0 && this.hoursValue < 0) {
            days = '-' + days;
        }
        return days;
    }

    ngOnInit(): void {
        this.init();
    }

    ngOnDestroy() {
        this.unsubscribe.unsubscribe();
    }

    private init() {
        this.timerdate = Math.trunc(new Date(this.date).getTime() / 1000);
        this.now = Math.trunc(new Date().getTime() / 1000);
        this.diff = Math.abs(this.now - this.timerdate);
        this.initDiffOffset = this.maxDashOffset / this.diff;
    }

    private check() {
        const oldNow = this.now;
        this.now = Math.trunc(new Date().getTime() / 1000);
        if (oldNow === this.now) {
            return;
        }
        const diff = this.timerdate - this.now;
        this.diff = Math.abs(this.now - this.timerdate);
        this.dashOffset += this.initDiffOffset;
        if (this.isCartCountDown && (diff <= 0)) {
            if (this.expiredCartTimeOut) {
                clearTimeout(this.expiredCartTimeOut);
            }
            this.expiredCartTimeOut = setTimeout(() => {
                this.cartService.clearCart();
                this.toastService.error('Votre panier a expiré !', 'Oups !');
                if (this.setInterval) {
                    this.cdr.detectChanges();
                    clearInterval(this.setInterval);
                    return;
                }
            }, 600);
        }
        this.cdr.detectChanges();
    }
}
