import {Api, Column} from "../decorators/api.decorator";
import {AccountModel} from "./account.model";
import {environment} from "../../../../environments/environment";

@Api({
    basePath: 'pages',
    baseURL: environment.cms_path
})
export class PageModel {

    @Column()
    id: string | null = null;
    @Column('account', AccountModel)
    account: AccountModel = new AccountModel();
    @Column()
    title: string | null = null;
    @Column()
    content: string | null = null;
}
