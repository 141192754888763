import {RepositoryService} from "../../services/repository.service";
import {PageModel} from "../models/page.model.";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class PageRepository extends RepositoryService<PageModel> {

    constructor(client: HttpClient) {
        super(client, PageModel);
    }
}
