import {Api, ApiRoute, Column} from "../decorators/api.decorator";
import {environment} from "../../../../environments/environment";
import {AccountModel} from "./account.model";

@Api({
    basePath: 'medias',
    baseURL: environment.cms_path,
})
@ApiRoute('NAVIGATE', '/accounts/{aid}/medias')
@ApiRoute('NAVIGATE_INSIDE', '/accounts/{aid}/medias/{fid}')
@ApiRoute('FOLDER', '/medias/folder')
@ApiRoute('FOLDER_CONTENT', '/medias/{fid}/folder')
export class MediaModel {

    @Column()
    id: string | null = null;
    @Column('account', AccountModel)
    account: AccountModel = new AccountModel();
    @Column()
    tag: string | null = null;
    @Column()
    publicName: string | null = null;
    @Column()
    fileName: string | null = null;
}
