<nav class="navbar navbar-expand-lg navbar-light bg-white sticky-top shadow-sm p-0" id="menu-top">
    <div class="container-fluid ps-0">
        <a class="navbar-brand p-0 p-lg-2 bg-primary"
           [routerLink]="['/']">
            <img src="/assets/img/logo-DWMtickets-blanc.svg"
                 alt="Logo les Vikings"
                 class="p-2"
                 width="auto"
                 height="100%">
        </a>
        <div class="h-100 d-flex align-items-center gap-2">
            <a [routerLink]="['/','mon-panier']"
               href="javascript:void(0)"
               class="btn btn-link p-0 text-primary d-lg-none h-100 d-flex flex-column align-items-center justify-content-center">
                <fa-layers [fixedWidth]="true" size="lg" class="me-2">
                    <fa-icon [icon]="{prefix: 'fas', iconName: 'shopping-bag'}"></fa-icon>
                    <fa-layers-counter *ngIf="cartService?.nbInCart!"
                                       content="{{cartService.nbInCart}}"
                                       [styles]="{'transform': 'none', 'font-size': '11px', 'top': '-6px', 'right': '-11px'}"
                                       position="top-right"></fa-layers-counter>
                </fa-layers>
            </a>
            <button class="navbar-toggler border-0 shadow-none"
                    type="button"
                    (click)="toggleNavbar(true)"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
        <div class="offcanvas offcanvas-start"
             tabindex="-1"
             [class.show]="showNavbar"
             id="offCanvasNavbar">
            <div class="offcanvas-header">
                <a class="offcanvas-title"
                   [routerLink]="['/']">
                    <img src="/assets/img/logo-DWMtickets-blanc.svg"
                         alt="Logo les Vikings"
                         width="200px"
                         height="100%">
                </a>
                <button type="button"
                        class="btn-close btn-close-white"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        (click)="toggleNavbar(false)"></button>
            </div>
            <div class="offcanvas-body d-flex flex-column flex-lg-row align-items-center">
                <app-menu-parts [items]="menuTop.children"></app-menu-parts>
                <div
                    class="d-flex flex-column flex-lg-row mt-auto mt-lg-0 ms-lg-auto align-items-lg-center justify-content-lg-end gap-2 customer-actions">
                    <ng-template [ngIf]="authService.isAuth" [ngIfElse]="notAuth">
                        <a [routerLink]="['/','mon-compte']"
                           href="javascript:void(0)"
                           class="btn btn-outline-primary rounded-pill me-lg-2 color-hover-white">
                            <fa-icon [icon]="{prefix: 'far', iconName: 'user'}" size="lg"></fa-icon>&nbsp;Mon compte
                        </a>
                        <a href="javascript:void(0)"
                           (click)="logout()"
                           class="btn btn-outline-primary rounded-pill color-hover-white">
                            <fa-icon [icon]="{prefix: 'fas', iconName: 'power-off'}" size="lg"></fa-icon>
                        </a>
                    </ng-template>
                    <ng-template #notAuth>
                        <a [routerLink]="['/','user','login']"
                           href="javascript:void(0)"
                           class="btn btn-outline-primary rounded-pill color-hover-white">
                            <fa-icon [icon]="{prefix: 'far', iconName: 'user'}" size="lg"></fa-icon>
                            Connexion
                        </a>
                    </ng-template>
                    <a [routerLink]="['/','mon-panier']"
                       [class.created]="cartService.cart && cartService.cart.expiredAt"
                       [class.not-empty]="cartService.nbInCart! > 0"
                       href="javascript:void(0)"
                       class="cart-btn btn btn-outline-primary rounded-pill color-hover-white d-none d-lg-flex align-items-center">
                        <span [class.me-3]="cartService.cart && cartService.cart.expiredAt">
                            <ng-container *ngIf="cartService?.nbInCart! > 0">
                                <fa-layers [fixedWidth]="true" size="lg" class="me-2">
                                    <fa-icon [icon]="{prefix: 'fas', iconName: 'shopping-bag'}"></fa-icon>
                                    <fa-layers-counter content="{{cartService.nbInCart}}"
                                                       [styles]="{'transform': 'none', 'font-size': '11px', 'top': '-6px', 'right': '-11px'}"
                                                       position="top-right"></fa-layers-counter>
                                </fa-layers>
                            </ng-container> Mon panier
                        </span>
                        <ng-container *ngIf="cartService.cart && cartService.cart.expiredAt">
                            <span class="text-white rounded-pill align-self-end">
                                <app-countdown [date]="cartService.cart.expiredAt"
                                               [displayLetters]="false"
                                               [display]="['m', 's']"
                                               [isCartCountDown]="true"
                                               class="d-inline-flex align-items-center"></app-countdown>
                            </span>
                        </ng-container>
                    </a>
                </div>
            </div>
        </div>
        <div class="offcanvas-backdrop fade show d-xl-none" *ngIf="showNavbar"></div>
    </div>
</nav>
