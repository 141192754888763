<div class="timer me-1">
    <div *ngIf="display.indexOf('d') >= 0">
        <span class="timer-num">{{ days }}</span>
        <span class="timer-cal" *ngIf="displayLetters">Jrs</span>
    </div>
    <div *ngIf="display.indexOf('d') >= 0">:</div>
    <div *ngIf="display.indexOf('h') >= 0">
        <span class="timer-num">{{ hours }}</span>
        <span class="timer-cal" *ngIf="displayLetters">Hrs</span>
    </div>
    <div *ngIf="display.indexOf('h') >= 0">:</div>
    <div *ngIf="display.indexOf('m') >= 0">
        <span class="timer-num">{{ minutes }}</span>
        <span class="timer-cal" *ngIf="displayLetters">Min</span>
    </div>
    <div *ngIf="display.indexOf('m') >= 0">:</div>
    <div *ngIf="display.indexOf('s') >= 0">
        <span class="timer-num">{{ seconds }}</span>
        <span class="timer-cal" *ngIf="displayLetters">Sec</span>
    </div>
</div>
<div id="countdown">
    <div id="countdown-number">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path d="M12 6.5V12L16 14"
                      stroke="#ffffff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"></path>
            </g>
        </svg>
    </div>
    <svg id="countdown-circle">
        <circle r="10.5" cx="12.5" cy="12.5" style="stroke-dashoffset: {{dashOffset}}px;"></circle>
    </svg>
</div>
