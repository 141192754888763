import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from "./_common/modules/auth";
import {RoleLoader} from "./_common/shared/security/role.loader";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {RefreshTokenInterceptor} from "./_common/interceptors/refresh-token.intercepetor";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {registerLocaleData} from "@angular/common";
import localeFr from '@angular/common/locales/fr';
import {CartService} from "./_common/services/cart.service";
import {NavigationService} from "./_common/services/navigation.service";
import {ModalModule} from "ngx-bootstrap/modal";
import {ToastrModule} from "ngx-toastr";
import {CarouselModule} from "ngx-bootstrap/carousel";
import {TheaterService} from "./_common/services/theater.service";
import {asyncScheduler, combineLatestAll, Observable, scheduled} from "rxjs";
import {TurnstileModule} from "./_common/shared/components/turnstile/turnstile.module";
import {environment} from "../environments/environment";
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

registerLocaleData(localeFr);

function appInitializer(
    authService: AuthService,
    cartService: CartService,
    theaterService: TheaterService
): () => Observable<any> {
    return () => scheduled([
        authService.getUserByToken(),
        cartService.load(),
        theaterService.load()], asyncScheduler)
        // @ts-ignore
        .pipe(combineLatestAll())
}

export function loadRouter(navService: NavigationService) {
    return () => navService.loadRouting();
}

function securityInitializer(roleLoader: RoleLoader) {
    return () => {
        return roleLoader.loadRoles();
    }
}


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        TurnstileModule.forRoot({
            publicKey: environment.turnstile_key,
            theme: 'light'
        }),
        ToastrModule.forRoot(),
        ModalModule.forRoot(),
        CarouselModule.forRoot(),
        FontAwesomeModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: loadRouter,
            multi: true,
            deps: [NavigationService],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: securityInitializer,
            multi: true,
            deps: [RoleLoader],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService, CartService, TheaterService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'fr-FR'
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
