import {AuthModel} from './auth.model';
import {Api, ApiRoute} from "../../../shared/decorators/api.decorator";
import {environment} from "../../../../../environments/environment";

@Api({
    basePath: 'users',
    baseURL: environment.auth_path
})
@ApiRoute('LOGIN', '/login')
@ApiRoute('REFRESH', '/token/refresh')
@ApiRoute('LOST', '/requests/lost-password')
export class UserModel extends AuthModel {

    id: string = '';
    password: string = '';
    email: string = '';
    roles: string[] = [];

    setUser(_user: unknown) {
        const user = _user as UserModel;
        this.id = user.id;
        this.password = user.password;
        this.email = user.email || '';
        this.roles = user.roles || [];
    }
}
