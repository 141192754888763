import {Injectable} from '@angular/core';
import {Observable, of, Subject, takeUntil, tap} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {UserModel} from '../models/user.model';
import {AuthModel} from '../models/auth.model';
import {Router} from '@angular/router';
import jwtDecode from "jwt-decode";
import {environment} from "../../../../../environments/environment";
import {UserRepository} from "../repository/user.repository";

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    // public fields
    public returnUrl: string = '/';
    public currentUser: UserModel | undefined = undefined;
    public isAuth: boolean = false;
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        public readonly userRepository: UserRepository,
        private readonly router: Router
    ) {
    }

    // public methods
    login(email: string, password: string): Observable<UserModel | undefined> {
        return this.userRepository.post<UserModel>(
            {username: email, password: password},
            {name: 'LOGIN', queryParams: {}},
            false, true
        ).pipe(
            map(auth => this.setAuthFromLocalStorage(auth!)),
            switchMap(_ => this.getUserByToken()),
            takeUntil(this.unsubscribe$)
        );

    }

    public initReturnUrl() {
        this.returnUrl = '/';
    }

    logout() {
        this.isAuth = false;
        localStorage.removeItem(this.authLocalStorageToken);
        this.currentUser = undefined;
    }

    refresh() {
        const storage = this.getAuthFromLocalStorage();
        if (storage === undefined) {
            return of(undefined);
        }
        return this.userRepository.post<UserModel>(
            {refresh_token: storage.refresh_token},
            {name: 'REFRESH', queryParams: {}}
        ).pipe(
            tap(auth => this.setAuthFromLocalStorage(auth!)),
            catchError((err) => {
                this.logout();
                this.router.navigate(['/users/login']).then(() => document.location.reload());
                return of(undefined);
            })
        )
    }

    getUserByToken(): Observable<UserModel | undefined> {
        const auth = this.getAuthFromLocalStorage();
        if (!auth || !auth.token) {
            return of(undefined);
        }

        this.currentUser = jwtDecode<UserModel>(auth.token);
        this.isAuth = true;
        return of(this.currentUser);
    }

    // need create new user then login
    registration(user: UserModel): Observable<any> {
        return this.userRepository.persist<UserModel>(user, null, null, false).pipe(
            switchMap(() => this.login(user.email, user.password)),
            takeUntil(this.unsubscribe$)
        );
    }

    forgotPassword(email: string): Observable<boolean> {
        return this.userRepository.post(
            {email: email},
            {name: '/requests/lost-password', queryParams: {}},
            false, true
        ).pipe(
            switchMap(_ => of(true)),
            takeUntil(this.unsubscribe$)
        )
    }

    public getAuthFromLocalStorage(): AuthModel | undefined {
        try {
            const lsValue = localStorage.getItem(this.authLocalStorageToken);
            if (!lsValue) {
                return undefined;
            }

            return JSON.parse(lsValue);
        } catch (error) {
            return undefined;
        }
    }

    // private methods
    private setAuthFromLocalStorage(auth: AuthModel): boolean {

        if (auth && auth.token) {
            localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
            return true;
        }
        return false;
    }
}
