<div class="footer">
    <div class="container h-100 py-4">
        <div class="row flex-column h-100 w-100">
            <div class="col-12">
                <div class="row align-items-center align-items-lg-end gap-4 gap-lg-0">
                    <div class="col-12 col-lg order-1 order-lg-0">
                        <strong>{{theaterService.currentTheater.name}}</strong>
                        <ul class="list-unstyled m-0">
                            <li>{{theaterService.currentTheater.address!.housenumber}} {{theaterService.currentTheater.address!.road}}</li>
                            <li>{{theaterService.currentTheater.address!.postalcode}} {{theaterService.currentTheater.address!.city}}</li>
                            <li *ngIf="theaterService.currentTheater.account!.phone">
                                tél. {{theaterService.currentTheater.account!.phone}}</li>
                        </ul>
                    </div>
                    <div class="col-12 col-lg ms-lg-auto order-0 order-lg-1">
                        <app-newsletters></app-newsletters>
                    </div>
                </div>
            </div>
            <div class="col-12 my-4 py-4 border-top border-bottom border-light border-opacity-25">
                <div class="row align-items-center gap-4 gap-lg-0">
                    <div class="col-12 col-lg-6">
                        <ul class="menu-bottom list-unstyled mb-0">
                            <li *ngFor="let bm of menuBottom.children" class="py-1">
                                <a [routerLink]="bm.url">{{bm!.name}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div
                            class="row align-items-center justify-content-center justify-content-lg-end gap-2 social-networks">
                            <a href="javascript:void(0);" class="col-auto">
                                <fa-stack size="lg" class="text-primary">
                                    <fa-icon [icon]="{prefix: 'fas', iconName: 'circle'}" stackItemSize="2x"
                                             [inverse]="true"></fa-icon>
                                    <fa-icon [icon]="{prefix: 'fab', iconName: 'facebook-f'}"
                                             stackItemSize="1x"></fa-icon>
                                </fa-stack>
                            </a>
                            <a href="javascript:void(0);" class="col-auto">
                                <fa-stack size="lg" class="text-primary">
                                    <fa-icon [icon]="{prefix: 'fas', iconName: 'circle'}" stackItemSize="2x"
                                             [inverse]="true"></fa-icon>
                                    <fa-icon [icon]="{prefix: 'fab', iconName: 'linkedin-in'}"
                                             stackItemSize="1x"></fa-icon>
                                </fa-stack>
                            </a>
                            <a href="javascript:void(0);" class="col-auto">
                                <fa-stack size="lg" class="text-primary">
                                    <fa-icon [icon]="{prefix: 'fas', iconName: 'circle'}" stackItemSize="2x"
                                             [inverse]="true"></fa-icon>
                                    <fa-icon [icon]="{prefix: 'fab', iconName: 'instagram'}"
                                             stackItemSize="1x"></fa-icon>
                                </fa-stack>
                            </a>
                            <a href="javascript:void(0);" class="col-auto">
                                <fa-stack size="lg" class="text-primary">
                                    <fa-icon [icon]="{prefix: 'fas', iconName: 'circle'}" stackItemSize="2x"
                                             [inverse]="true"></fa-icon>
                                    <fa-icon [icon]="{prefix: 'fab', iconName: 'youtube'}" stackItemSize="1x"></fa-icon>
                                </fa-stack>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row align-items-end justify-content-between">
                    <div class="col-auto">
                        <small>&copy;&nbsp;{{today | date:'yyyy'}} {{theaterService.currentTheater.account!.tradeName}}
                            — Tous droits réservés</small>
                    </div>
                    <div class="col-auto">
                        <small>Pour le respect de votre vie privée, ce site n'utilise pas de cookie.</small>
                    </div>
                    <div class="col-auto">
                        <a href="https://dwm-it.com/" target="_blank" title="DWM Tickets">
                            <img src="assets/img/dwm_tickets.png"
                                 class="img-fluid"
                                 alt="DWM Tickets"
                                 loading="lazy">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
