import {RepositoryService} from "../../services/repository.service";
import {CartModel} from "../models/cart.model";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class CartRepository extends RepositoryService<CartModel> {

    constructor(client: HttpClient) {
        super(client, CartModel);
    }
}
