import {Component, OnDestroy} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ToastrService} from "ngx-toastr";
import {Subject, takeUntil} from "rxjs";
import {AccountRepository} from "../../../_common/shared/repository/account.repository";
import {AccountModel} from "../../../_common/shared/models/account.model";

@Component({
    selector: 'app-newsletters',
    template: `
        <form #newsletterForm="ngForm" (ngSubmit)="onSubmit(newsletterForm)" novalidate class="position-relative z-0">
            <label for="newsletter-input" class="ps-4 mb-2 fw-bold">Pour suivre toute notre actualité, abonnez-vous à
                notre newsletter : </label>
            <div class="input-group input-group-md text-primary bg-white rounded-pill p-2">
                <input name="newsletterEmail"
                       ngModel
                       required
                       #newsletterEmail="ngModel"
                       type="email"
                       id="newsletter-input"
                       class="form-control border-0 shadow-none"
                       email
                       placeholder="Votre adresse email"
                       aria-label="Votre adresse email"
                       aria-describedby="newsletter-btn">
                <button class="btn btn-link border-0"
                        type="submit"
                        id="newsletter-btn">
                    <fa-icon [icon]="{prefix: 'fas', iconName: 'arrow-right-long'}" size="1x"></fa-icon>
                </button>
            </div>
            <strong class="text-danger small z-1 position-absolute bottom-0 end-0 mb-1 me-4"
                    *ngIf="newsletterForm.submitted && !newsletterForm.valid">
                <ng-container *ngIf="newsletterForm.controls.newsletterEmail.errors?.required">Un email est requis.
                </ng-container>
                <ng-container *ngIf="newsletterForm.controls.newsletterEmail.errors?.email">Vous devez renseigner un
                    email valide.
                </ng-container>
            </strong>
        </form>
    `,
    styles: [`
        :host {
            #newsletter-input {
                &, &:focus, &:active {
                    background-color: transparent;
                }
            }
        }
    `]
})
export class NewsletterComponent implements OnDestroy {
    private unsubscribe$: Subject<void> = new Subject();

    constructor(
        private readonly accountRepository: AccountRepository,
        private readonly toastr: ToastrService,
    ) {
    }

    onSubmit(f: NgForm) {
        if (!f.valid) {
            return;
        }
        this.accountRepository.post<AccountModel>(
            {email: f.value.newsletterEmail},
            {
                name: 'NEWSLETTERS',
                queryParams: {}
            },
            false
        ).pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(
            {
                next: () => {
                    this.toastr.success('Inscription à la Newsletter validée&nbsp;!', '', {
                        enableHtml: true
                    });
                    f.resetForm();
                },
                error: err => {
                    f.resetForm();
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }
}
