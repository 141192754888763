import {Api, ApiRoute, Column, Ignore} from "../../decorators/api.decorator";
import {environment} from "../../../../../environments/environment";
// @ts-ignore
import {v4 as uuidv4} from 'uuid';

@Api({
    basePath: 'items',
    baseURL: environment.cms_path
})
@ApiRoute('BASE', '/menus/{mid}/items')
export class ItemModel {

    @Column()
    id: string | null = null;
    @Ignore()
    tmpId: string = '';
    @Column('children', ItemModel)
    children: ItemModel[] = [];
    @Column()
    name: string | null = null;
    @Column()
    url: string | null = null;
    @Column()
    position: number = 0;

    constructor() {
        this.tmpId = uuidv4();
    }
}

