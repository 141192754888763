import {Component, Inject, Injector, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {forkJoin, Subject, takeUntil} from 'rxjs';
import {CartService} from "../../_common/services/cart.service";
import {MenuRepository} from "../../_common/shared/repository/menu.repository";
import {MenuModel} from "../../_common/shared/models/menu.model";
import {AuthService} from "../../_common/modules/auth";
import {TheaterService} from "../../_common/services/theater.service";

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {

    public open: boolean = false;
    private unsubscribe$: Subject<void> = new Subject<void>();
    public menuTop: MenuModel = new MenuModel();
    public menuBottom: MenuModel = new MenuModel();

    constructor(
        public readonly router: Router,
        private readonly injector: Injector,
        private readonly menuRepository: MenuRepository,
        public readonly cartService: CartService,
        public readonly authService: AuthService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    ngOnInit(): void {
        forkJoin(
            {
                menuTop: this.menuRepository.findOneBy<MenuModel>(null, {
                    name: 'BY_SLUG',
                    queryParams: {slug: 'menu-top'}
                }, false),
                menuBottom: this.menuRepository.findOneBy<MenuModel>(null, {
                    name: 'BY_SLUG',
                    queryParams: {slug: 'menu-bottom'}
                }, false)
            }
        ).pipe(takeUntil(this.unsubscribe$))
            .subscribe(({menuTop, menuBottom}) => {
                this.menuTop = menuTop || new MenuModel();
                this.menuBottom = menuBottom || new MenuModel();
            })
    }

    logout() {
        this.authService.logout();
        this.cartService.clearCart();
        this.cartService.clear();
        this.router.navigate(['/']);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    openMenu(value: boolean) {
    }
}
