import {RepositoryService} from "../../../services/repository.service";
import {SessionModel} from "../../models/show/session.model";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class SessionRepository extends RepositoryService<SessionModel> {

    constructor(client: HttpClient) {
        super(client, SessionModel);
    }
}
