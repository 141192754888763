<ul *ngIf="items.length > 0"
    class="menu-block-depth{{depth}}"
    [ngClass]="{
    'navbar-nav align-items-lg-center justify-content-end flex-grow-1 pe-3': depth === 1,
    'menu-block': depth <= 2,
    'flex-column': depth === 2,
    'menu-block-flat': depth > 2
    }">
    <li class="menu-item nav-item d-lg-none"
        *ngIf="depth === 1">
        <a [routerLink]="['/']" title="Accueil" class="menu-link nav-link"><span>Accueil</span></a>
    </li>
    <li *ngFor="let item of items"
        class="menu-item nav-item"
        [class.menu-item-flat]="depth > 1"
        [class.submenu]="item.children.length > 0"
        #submenu>
        <ng-template [ngIfElse]="externalLink" [ngIf]="isInternal(item.url)">
            <ng-template [ngIfElse]="noLink" [ngIf]="item.url !== null">
                <a [routerLink]="item.url" [title]="item.name" class="menu-link nav-link" #menuLink>
                    <span>{{item.name}}</span>
                </a>
                <span class="submenu-arrow d-lg-none text-white"
                      *ngIf="item.children.length > 0"
                      (click)="toggleMenu($event, menuLink, submenu)">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="20"
                                 height="20"
                                 fill="currentColor"
                                 class="bi bi-chevron-down icon"
                                 viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path>
                            </svg>
                </span>
            </ng-template>
            <ng-template #noLink>
                <a class="menu-link disabled"><span>{{item.name}}</span></a>
            </ng-template>
        </ng-template>
        <ng-template #externalLink>
            <a [href]="item.url" [title]="item.name" class="menu-link nav-link" target="_blank">
                <span>{{item.name}}</span>
            </a>
        </ng-template>
        <div *ngIf="item.children.length > 0"
             [ngClass]="{'menu-sub': depth >= 1 && needHover, 'w-100': depth >= 2}">
            <div [ngClass]="{'container px-lg-4': depth === 1 && needHover}" class="px-0 mx-0">
                <app-menu-parts [items]="item.children" [depth]="depth + 1"></app-menu-parts>
            </div>
        </div>
    </li>
</ul>
