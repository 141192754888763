import {Api, ApiRoute, Column} from "../decorators/api.decorator";
import {environment} from "../../../../environments/environment";
import {CategoryModel} from "./category.model";
import {SeoModel} from "./seo.model";
import {MediaModel} from "./media.model";
import {RoomModel} from "./theater/room.model";

@Api({
    basePath: 'shows',
    baseURL: environment.theater_path
})
@ApiRoute('TARGET', '/theaters/{tid}/shows')
@ApiRoute('MEDIA', '/shows/{sid}/media')
export class ShowModel {

    @Column()
    id: string = '';
    @Column('category', CategoryModel)
    category: CategoryModel | null = null;
    @Column('room', RoomModel)
    room: RoomModel | null = null;
    @Column()
    title: string = '';
    @Column()
    subtitle: string = '';
    @Column()
    artists: string = '';
    @Column()
    description: string = '';
    @Column()
    videoLink: string = '';
    @Column()
    active: string = '';
    @Column()
    namedPlace: string = '';

    @Column('media', MediaModel)
    media: MediaModel[] | null = [];
    @Column()
    begin: string | null = null;
    @Column()
    end: string | null = null;
    seo: SeoModel = new SeoModel();

    @Column()
    full:boolean = false;
}
