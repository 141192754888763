import {RepositoryService} from "../../services/repository.service";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {CustomerModel} from "../models/customer.model";

@Injectable({
    providedIn: 'root'
})
export class CustomerRepository extends RepositoryService<CustomerModel> {
    constructor(client: HttpClient) {
        super(client, CustomerModel);
    }
}
