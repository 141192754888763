import {Api, ApiRoute, Column, ToInt} from "../../decorators/api.decorator";
import {TheaterModel} from "../theater.model";
import {environment} from "../../../../../environments/environment";


@Api({
  basePath: 'rooms',
  baseURL: environment.theater_path
})
@ApiRoute('BY_THEATER','/theaters/{tid}/rooms')
export class RoomModel {
  @Column()
  id: string = '';
  @Column('theater', TheaterModel)
  theater: TheaterModel | null = null;
  @Column()
  name: string = '';
  @Column()
  @ToInt()
  nbPlaces: number = 0;
}
