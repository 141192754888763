import {Component, ElementRef, forwardRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TurnstileService} from "./turnstile.service";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'cloudflare-turnstile',
  templateUrl: './turnstile.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TurnstileComponent),
      multi: true
    }
  ]
})
export class TurnstileComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @ViewChild('turnstileBox', {static: true}) turnstileBox: ElementRef;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private readonly turnstileService: TurnstileService
  ) {
    this.turnstileService.token$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((token: string | null) => {
      this.onChange(token);
    })
  }

  ngOnInit(): void {
    this.turnstileService.render(this.turnstileBox.nativeElement)
  }

  ngOnDestroy(): void {
    this.turnstileService.remove();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(obj: any): void {
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  private onChange: any = () => {
  }

  private onTouch: any = () => {
  };
}
