import {ShowModel} from "../show.model";
import {Api, ApiRoute, Column, Ignore} from "../../decorators/api.decorator";
import {environment} from "../../../../../environments/environment";
import {isBlank} from "../../utils/utils";
import {SeatGroupStatsInterface} from "./session/seat-group/seat-group-stats.interface";

@Api({
    basePath: 'sessions',
    baseURL: environment.theater_path,
})
@ApiRoute('TARGET', '/shows/{sid}/sessions')
@ApiRoute('BOOK', '/sessions/{sid}/book')
export class SessionModel {
    @Column()
    id: string = '';
    @Column('show', ShowModel)
    show: ShowModel = new ShowModel();
    @Column()
    nbSeats: number = 0;
    @Column()
    seatsBooked: number = 0;
    @Column()
    seatsLocked: number = 0;
    @Column()
    seatsUnavailable: number = 0;
    @Column()
    canceledAt: string = '';
    @Ignore()
    stats: SeatGroupStatsInterface = {};

    private _start: string = '';

    @Column()
    get start(): string {
        return this._start;
    }

    set start(value: string) {
        this._start = value;
        const d = new Date(value);
        this.date = {
            year: d.getFullYear(),
            month: d.getMonth() + 1,
            day: d.getDate()
        };

        this.time = {
            hour: d.getHours(),
            minute: d.getMinutes()
        };
    }

    private _date: { year: number | null, month: number | null, day: number | null } = {
        year: null,
        month: null,
        day: null
    };
    @Ignore()
    get date(): any {
        return this._date;
    }

    set date(value: any) {
        this._date = value;
        this.applyDate();
    }

    private _time: { hour: number | null, minute: number | null } = {hour: null, minute: null};

    @Ignore()
    get time(): any {
        return this._time;
    }

    set time(value: any) {
        this._time = value;
        this.applyDate();
    }

    applyDate() {
        let date = '1970-01-01';
        if (!isBlank(this._date)) {
            date = this._date.year + '-' + this._date.month + '-' + this._date.day;
        }
        let time = '00:00:00';
        if (!isBlank(this._time)) {
            time = this._time.hour + ':' + this._time.minute + ':00';
        }
        this._start = date + ' ' + time;
    }
}
