import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter, Subject, takeUntil} from 'rxjs';
import {MenuModel} from 'src/app/_common/shared/models/menu.model';
import {MenuRepository} from '../../../_common/shared/repository/menu.repository';
import {CartService} from '../../../_common/services/cart.service';
import {AuthService} from '../../../_common/modules/auth';
import {DOCUMENT} from "@angular/common";

@Component({
    selector: 'app-header, [app-header]',
    templateUrl: './header.component.html',
    styles: [`
        :host {
            display: contents;
        }
    `]
})
export class HeaderComponent implements OnInit, OnDestroy {

    public open: boolean = false;
    public menuTop: MenuModel = new MenuModel();
    public showNavbar = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public readonly router: Router,
        private readonly menuRepository: MenuRepository,
        public cartService: CartService,
        public readonly authService: AuthService,
    ) {
        this.router.events
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(e => e instanceof NavigationEnd)
            )
            .subscribe(event => {
                this.showNavbar = false;
            });
    }

    ngOnInit(): void {
        this.menuRepository.findOneBy<MenuModel>(
            null,
            {
                name: 'BY_SLUG',
                queryParams: {slug: 'menu-top'}
            },
            false
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(menuTop => {
                this.menuTop = menuTop || new MenuModel();
            });
    }

    logout() {
        this.authService.logout();
        this.cartService.clearCart();
        this.cartService.clear();
        this.router.navigate(['/']);
    }

    toggleNavbar(value: boolean) {
        this.showNavbar = value;
        const nav = this.document.getElementById('offCanvasNavbar');
        if (!nav) {
            return;
        }
        if (value) {
            nav.style.visibility = 'visible';
        } else {
            setTimeout(() => {
                nav.style.visibility = 'hidden';
            }, 300);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }
}
