import {Api, ApiRoute, Column, ToFloat, ToInt} from "../../decorators/api.decorator";
import {CodeModel} from "../shopping/code.model";
import {SeatModel} from "../show/session/seat.model";
import {environment} from "../../../../../environments/environment";
import {SubscriptionModel} from "../subscription.model";

@Api({
    basePath: 'order-rows',
    baseURL: environment.shopping_path
})
@ApiRoute('BASE', '/orders/{ido}/rows')
export class RowModel {
    @Column()
    id: string = '';
    @Column()
    ref: string = '';
    @Column()
    type: string = '';
    @Column()
    data: any = null;
    @Column()
    @ToInt()
    qty: number = 0;
    @Column()
    productName: string = '';
    @Column()
    @ToFloat()
    total: number = 0;
    @Column()
    @ToFloat()
    totalTax: number = 0;
    @Column()
    @ToFloat()
    totalIncTax: number = 0;

    @Column('code', CodeModel)
    code: CodeModel | null = null;

    seat: SeatModel | null = null;
    @Column('giftCodes', CodeModel)
    giftCodes: CodeModel[] = [];

    @Column('subscription', SubscriptionModel)
    subscription: SubscriptionModel | null = null;

    @Column()
    refundedAt: string = '';
}
