import {isBlank} from "../utils/utils";

export interface ApiDecorator {
    primary?: string;
    baseURL?: string;
    basePath?: string;
    routes?: { name: string, path: string }[];
}

export class ApiValues {
    public __primary: string = '';
    public __primaryValue: string = '';
    public __baseURL: string = '';
    public __basePath: string = '';
    public __fieldName: { [index: string]: { fieldName: string, type: any } } = {};
    public __always: string[] = [];
    public __plain: string[] = [];
    public __ignoreList: string[] = [];
}

export function Api(config: ApiDecorator): ClassDecorator {
    return function (target: any) {
        target.prototype.__baseURL = config.baseURL;
        target.prototype.__basePath = config.basePath;
        target.prototype.__primary = config.primary || 'id';
        if (!isBlank(config.routes)) {
            if (isBlank(target.prototype.__route)) {
                target.prototype.__route = {};
            }
            config.routes.map((route) => {
                target.prototype.__route[route.name] = route.path;
            });
        }
        return target;
    }
}

export function ApiRoute(name: string, path: string, base_url: string | null = null): ClassDecorator {
    return function (target: any) {
        if (isBlank(target.prototype.__route)) {
            target.prototype.__route = {};
        }
        target.prototype.__route[name] = {path: path, base_url: base_url};
        return target;
    }
}

export function Column(name: string | undefined = undefined, type: any = 'string'): PropertyDecorator {
    return function (target, propertyKey) {
        if (!target.hasOwnProperty('__fieldName')) {
            target.constructor.prototype.__fieldName = {};
        }
        target.constructor.prototype.__fieldName[name || propertyKey] = {fieldName: propertyKey, type: type}
    }
}

export function ToFloat(): PropertyDecorator {
    return function (target, propertyKey) {
        Object.defineProperty(target, '_' + propertyKey.toString(), {writable: true, enumerable: false});
        Object.defineProperty(target, propertyKey, {
            get: function () {
                return this['_' + propertyKey.toString()];
            },
            set: function (newValue: string | null) {
                this['_' + propertyKey.toString()] = newValue !== null ? parseFloat(newValue) : null;
            },
            enumerable: true,
            configurable: true,
        });
    }
}

export function ToInt(): PropertyDecorator {
    return function (target, propertyKey) {
        Object.defineProperty(target, '_' + propertyKey.toString(), {writable: true, enumerable: false});
        Object.defineProperty(target, propertyKey, {
            get: function () {
                return this['_' + propertyKey.toString()];
            },
            set: function (newValue: string | null) {
                this['_' + propertyKey.toString()] = newValue !== null ? parseInt(newValue) : null;
            },
            enumerable: true,
            configurable: true,
        });
    }
}

export function ToTime(): PropertyDecorator {
    return function (target, propertyKey) {
        Object.defineProperty(target, '_' + propertyKey.toString(), {writable: true, enumerable: false});
        Object.defineProperty(target, propertyKey, {
            get: function () {
                return this['_' + propertyKey.toString()];
            },
            set: function (newValue: string | null) {
                if (newValue !== null) {
                    if (newValue.match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/g) !== null) {
                        this['_' + propertyKey.toString()] = newValue;
                    } else {
                        this['_' + propertyKey.toString()] = newValue + ':00';
                    }
                } else {
                    this['_' + propertyKey.toString()] = null;
                }
            },
            enumerable: true,
            configurable: true,
        });
    }
}

export function Ignore(): PropertyDecorator {
    return function (target, propertyKey) {
        if (!target.hasOwnProperty('__ignoreList')) {
            target.constructor.prototype.__ignoreList = [];
        }
        target.constructor.prototype.__ignoreList.push(propertyKey);
    }
}

export function Always(): PropertyDecorator {
    return function (target, propertyKey) {
        if (!target.hasOwnProperty('__always')) {
            target.constructor.prototype.__always = [];
        }
        target.constructor.prototype.__always.push(propertyKey);
    }
}
