import {Inject, Injectable, Optional} from '@angular/core'
import {Router, Routes} from '@angular/router'
import {SeoRepository} from "../shared/repository/seo.repository";
import {LayoutComponent} from "../../global/layout/layout.component";
import {APP_ROUTING} from "../../../app_routing";
import {CacheService} from "./cache.service";
import {ContentService} from "./content.service";

export const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('../../global/layout/layout.module').then((m) => m.LayoutModule),
    }
];
export const dynRoutes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'error',
                loadChildren: () =>
                    import('../../global/errors/errors.module').then((m) => m.ErrorsModule),
            },
            {
                path: 'payment',
                loadChildren: () =>
                    import('../../pages/payment/payment.module').then((m) => m.PaymentModule),
            },
            {
                path: 'user',
                loadChildren: () =>
                    import('../modules/auth/auth.module').then((m) => m.AuthModule),
            },
            {
                path: 'mon-panier',
                loadChildren: () =>
                    import('../../pages/recap-cart/recap-cart.module').then((m) => m.RecapCartModule),
            },
            {
                path: 'mon-compte',
                loadChildren: () =>
                    import('../../pages/account/account.module').then((m) => m.AccountModule),
            },
            {
                path: 'categorie',
                loadChildren: () =>
                    import('../../pages/category/category.module').then((m) => m.CategoryModule),
            },
        ],
    },
];

@Injectable({providedIn: 'root'})
export class NavigationService {
    public routingConfig: any = null;
    private routingConfigString: string = '';
    public spectaclePath = '/spectacles';

    constructor(
        private readonly seoRepository: SeoRepository,
        private router: Router,
        private cache: CacheService,
        private contentService: ContentService,
        @Optional() @Inject(APP_ROUTING) private routing: string,
    ) {
        if (!this.cache.hasStateKey('ROUTING_CONFIG')) {
            this.routingConfigString = this.routing;
            this.cache.saveStateKey('ROUTING_CONFIG', this.routing);
        } else {
            this.routingConfigString = this.cache.getStateKey('ROUTING_CONFIG', '');
        }
    }

    public loadRouting(): Promise<boolean> {
        this.routingConfig = JSON.parse(this.routingConfigString);
        return new Promise((resolve => {
            this.routingConfig.members.map((seo: any) => {
                dynRoutes[0].children!.push({
                    path: seo.url?.replace(/^\//, ''),
                    loadChildren: () => {
                        if (seo.type === 'show_list') {
                            this.spectaclePath = seo.url;
                            return import('../../pages/spectacle/spectacle.module').then((m) => m.SpectacleModule);
                        } else if (seo.type === 'home_page') {
                            return import('../../pages/home/home.module').then((m) => m.HomeModule);
                        } else if (seo.type === 'gift_list') {
                            return import('../../pages/gift-box/gift-box.module').then((m) => m.GiftBoxModule);
                        } else if (seo.type === 'contact') {
                            return import('../../pages/contact/contact.module').then((m) => m.ContactModule);
                        }
                        return import('../../global/errors/errors.module').then((m) => m.ErrorsModule);
                    }
                });
            });
            dynRoutes[0].children!.push(
                {
                    path: '**',
                    loadChildren: () =>
                        import('../../pages/page/page.module').then((m) => m.PageModule),
                }
            );
            this.router.resetConfig(routes);
            resolve(true);
        }));
    }
}
