import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {SeoRepository} from "../shared/repository/seo.repository";
import {PageRepository} from "../shared/repository/page.repository";
import {DomSanitizer, Meta, SafeHtml, Title} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {SeoModel} from "../shared/models/seo.model";
import {of, Subject, switchMap, takeUntil, throwError} from "rxjs";
import {isBlank} from "../shared/utils/utils";
import {PageModel} from "../shared/models/page.model.";
import {catchError} from "rxjs/operators";
import {DOCUMENT} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class ContentService {

    public pageContent: SafeHtml | null = null;
    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private meta: Meta,
        private seoRepository: SeoRepository,
        private pageRepository: PageRepository,
        private sanitizer: DomSanitizer,
        private router: Router,
        private titleService: Title,
        @Inject(DOCUMENT) private doc: any,
        @Inject(PLATFORM_ID) private platformId: any
    ) {

    }

    public loadSeoAndContent() {
        this.seoRepository.findOneBy<SeoModel>(
            null,
            {name: 'BY_URL', queryParams: {url: this.router.url}},
            false
        ).pipe(
            switchMap((seo: SeoModel | null) => {
                if (isBlank(seo)) {
                    return of(null);
                }
                if (seo.type === 'category') {
                    this.router.navigate(['error', '404']).then();
                    return throwError(null);
                }
                this.loadSeo(seo || new SeoModel());
                return this.pageRepository.find<PageModel>(seo?.ref || '', null, false)
                    .pipe(
                        catchError((err) => {
                            this.router.navigate(['error', '404']).then();
                            return throwError(err);
                        })
                    );
            }),
            catchError((err) => {
                this.router.navigate(['error', '404']).then();
                return throwError(err);
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe((page: PageModel | null) => {
            this.pageContent = this.sanitizer.bypassSecurityTrustHtml(page?.content || '');
        })
    }

    loadSeo(seo: SeoModel) {
        this.titleService.setTitle(seo.meta.title);
        if (isBlank(seo.meta.description)) {
            this.meta.removeTag('name="description"');
        } else {
            this.meta.updateTag({name: 'description', content: seo.meta.description});
        }
        if (seo.meta.canonical !== null) {
            this.updateCanonical(seo.meta.canonical);
        } else {
            this.removeCanonical();
        }

        if ((isBlank(seo.meta?.indexation) ? true : seo.meta?.indexation) === false) {
            this.meta.updateTag({name: 'robots', content: 'noindex, follow'});
        } else {
            this.meta.removeTag('name="robots"');
        }
    }

    setPageTitle(title: string) {
        this.titleService.setTitle(title);
    }

    updateDescription(description: string) {
        this.meta.updateTag({name: 'description', content: description})
    }

    public removeCanonical() {
        let link = this.doc.head.querySelector('link[rel="canonical"]');
        if (link !== null && link !== undefined) {
            link.remove();
        }
    }

    public updateCanonical(href: string) {
        let link = this.doc.head.querySelector('link[rel="canonical"]');
        if (link === null || link === undefined) {
            link = this.createCanonical()
        }
        link.setAttribute('href', href || '/');
    }

    private createCanonical() {
        let link: HTMLElement = this.doc.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.doc.head.appendChild(link);
        return link;
    }

    private removeRobots() {

    }
}
