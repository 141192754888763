import {InjectionToken} from "@angular/core";
import {PaygreenMode} from "../paygreen/paygreen.vars";

declare global {
  interface Window {
    turnstile: any;
  }
}

export const TURNSTILE_OPTIONS = new InjectionToken('TURNSTILE_OPTIONS');

export interface TurnstileConfig {
  theme: string;
  publicKey: string;
}
