const objectProto = Object.prototype;
const toString = Object.prototype.toString;

export function isBlank<T>(value: any | null | undefined): value is (null | undefined) {
    if (value === '' || value === null || value === undefined) {
        return true;
    }

    if (typeof value !== 'object') {
        return false;
    }
    const tag = getTag(value)
    if (tag == '[object Map]' || tag == '[object Set]' || tag == '[object Blob]') {
        return !value.size
    }
    if (isPrototype(value)) {
        return !Object.keys(value).length
    }
    for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
            return false
        }
    }
    return true
}

function isPrototype(value: any) {
    const Ctor = value && value.constructor
    const proto = (typeof Ctor === 'function' && Ctor.prototype) || objectProto

    return value === proto
}

function getTag(value: any) {
    if (value == null) {
        return value === undefined ? '[object Undefined]' : '[object Null]'
    }
    return toString.call(value)
}
