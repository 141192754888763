import {Api, ApiRoute, Column} from "../decorators/api.decorator";
import {environment} from "../../../../environments/environment";
import {AddressModel} from "./address.model";

@Api({
    basePath: 'users',
    baseURL: environment.account_path
})
@ApiRoute('ME', '/me')
@ApiRoute('PASSWORD', '/me/password', environment.auth_path)
export class CustomerModel {

    @Column()
    id: string = '';
    @Column()
    password: string = '';
    @Column()
    email: string = '';
    @Column()
    firstname: string = '';
    @Column()
    lastname: string = '';
    @Column()
    phone: string = '';
    @Column()
    gender: string = '';

    @Column()
    address: AddressModel = new AddressModel();
    @Column()
    token: string | null = null;
}
