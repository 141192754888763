import {Inject, Injectable, OnDestroy, PLATFORM_ID} from "@angular/core";
import {CartModel} from "../shared/models/cart.model";
import {
    BehaviorSubject,
    concat,
    filter,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    takeUntil,
    tap,
    throwError
} from "rxjs";
import {SessionModel} from "../shared/models/show/session.model";
import {GroupModel} from "../shared/models/seat/group.model";
import {PriceModel} from "../shared/models/show/price.model";
import {SessionRepository} from "../shared/repository/show/session.repository";
import {CartSummaryModel} from "../shared/models/cart.summary.model";
import {isBlank} from "../shared/utils/utils";
import {RowModel} from "../shared/models/cart/row.model";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {CartRepository} from "../shared/repository/cart.repository";
import {RowRepository} from "../shared/repository/cart/row.repository";
import {GiftboxModel} from "../shared/models/shopping/giftbox.model";
import {isPlatformServer} from "@angular/common";
import {AuthService} from "../modules/auth";
import {OrderModel} from "../shared/models/order.model";
import {OrderRepository} from "../shared/repository/order.repository";
import {CustomerModel} from "../shared/models/customer.model";
import {CustomerRepository} from "../shared/repository/customer.repository";

@Injectable({providedIn: 'root'})
export class CartService implements OnDestroy {

    public email: string = '';
    public cartId: string | null = null;
    public nbInCart: number = 0;
    public currentOrderId: string | null = null;
    public cartSummary: { [index: string]: CartSummaryModel } = {};
    public orderPaidSubject: BehaviorSubject<OrderModel | null> = new BehaviorSubject<OrderModel | null>(null);
    public orderPaid$: Observable<OrderModel | null> = this.orderPaidSubject.asObservable();
    private callCartSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public callCart$: Observable<boolean> = this.callCartSubject.asObservable();
    private callCartParsedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public callCartParsed$: Observable<boolean> = this.callCartParsedSubject.asObservable();
    private unsubscribe$: Subject<void> = new Subject<void>();

    private _cart: CartModel = new CartModel();

    get cart(): CartModel {
        return this._cart;
    }

    set cart(value: CartModel) {
        this._cart = value;
        this.cartId = value.id;
        if (!isPlatformServer(this.platformId)) {
            localStorage.setItem('cart', value.id);
        }
    }

    constructor(
        private readonly cartRepository: CartRepository,
        private readonly customerRepository: CustomerRepository,
        private readonly orderRepository: OrderRepository,
        private readonly sessionRepository: SessionRepository,
        private readonly rowRepository: RowRepository,
        private readonly authService: AuthService,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        if (!isPlatformServer(this.platformId)) {
            this.cartId = localStorage.getItem('cart');
        }
    }

    load() {
        if (!isPlatformServer(this.platformId)) {
            this.cartId = localStorage.getItem('cart') || null;
            this.refreshCart().pipe(takeUntil(this.unsubscribe$)).subscribe();
        }
        return of(true);
    }

    getCartSummaryValues(): CartSummaryModel[] {
        return Object.values(this.cartSummary) || [];
    }

    getQtyForKey(key: string) {
        if (isBlank(this.cartSummary[key])) {
            return 0;
        }
        return this.cartSummary[key].qty;
    }

    refreshCart() {
        if (!isBlank(this.cartId)) {
            this.callCartSubject.next(true);
            return this.cartRepository
                .find<CartModel>(this.cartId, null, this.isAuth())
                .pipe(
                    catchError((err: HttpErrorResponse) => {
                            this.cartId = null;
                            this.cartSummary = {};
                            this.cart = new CartModel();
                            return throwError(() => err);
                        }
                    ),
                    map((cart) => {
                        if (cart && !cart.rows.length && cart.total === 0) {
                            this.clearCart();
                            this.callCartSubject.next(false);
                            return;
                        }
                        this.cart = cart || new CartModel();
                        this.parseCart();
                        this.callCartSubject.next(false);
                    }),
                    takeUntil(this.unsubscribe$));
        }
        return of(undefined);
    }

    clearCart() {
        this.cartId = null;
        this.cartSummary = {};
        this.cart = new CartModel();
        this.nbInCart = 0;
        this.callCartSubject.next(true);
    }

    createCart() {
        this.callCartSubject.next(true);
        if (this.isAuth()) {
            this.customerRepository.findOneBy<CustomerModel>(null, {name: 'ME', queryParams: {}})
                .pipe(
                    switchMap(customer => this.cartRepository
                        .post<CartModel>(customer, {name: 'CREATE', queryParams: {}})),
                    takeUntil(this.unsubscribe$)
                ).subscribe((cart) => {
                this.cartId = cart?.id || '';
                this.cart = cart || new CartModel();
                this.parseCart();
                this.callCartSubject.next(false);
            })
        } else {
            this.cartRepository
                .post<CartModel>({email: this.email}, {name: 'CREATE', queryParams: {}}, this.isAuth())
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((cart) => {
                    this.cartId = cart?.id || '';
                    this.cart = cart || new CartModel();
                    this.parseCart();
                    this.callCartSubject.next(false);
                });
        }
    }

    updateCartInfo(user: CustomerModel) {
        if (!isBlank(this.cartId)) {
            return this.cartRepository.put<CartModel>(
                user,
                {name: '/carts/{cid}', queryParams: {cid: this.cartId}}
            )
        }

        return of(undefined);
    }

    changeQty(session: SessionModel, seatGroup: GroupModel, price: PriceModel, qty: number = 0) {
        const rows = this.cart.rows
            .filter((row) => row.type === 'show')
            .filter(
                (row) => row.data.session.id === session.id && row.data.price.id === price.id && row.data.group.id === seatGroup.id
            );
        const delta = qty - rows.length;
        if (delta > 0) {
            this.addShow(session, price, delta);
        } else {
            this.removeShow(session, seatGroup, price, Math.abs(delta));
        }
    }

    addShow(session: SessionModel, price: PriceModel, qty: number = 1) {
        const data = {
            seats: new Array(qty).fill({
                price: price.id
            })
        };
        if (isBlank(this.cartId)) {
            this.createCart();
            const subscriber = this.callCart$.pipe(
                filter(value => !value),
                switchMap(_ =>
                    this.sessionRepository.post(
                        data,
                        {name: 'BOOK', queryParams: {sid: session.id}},
                        this.isAuth(),
                        true
                    )
                ),
                switchMap((response: any) => {
                    return this.cartRepository.post<CartModel>(
                        response,
                        {name: 'ADD_PRODUCT', queryParams: {idc: this.cart.id, type: 'show'}},
                        this.isAuth()
                    )
                }),
                takeUntil(this.unsubscribe$)
            ).subscribe((cart) => {
                this.cart = cart || new CartModel();
                this.parseCart();
                subscriber.unsubscribe();
            });
        } else {
            this.sessionRepository.post(
                data,
                {name: 'BOOK', queryParams: {sid: session.id}},
                this.isAuth(),
                true
            ).pipe(
                switchMap((response: any) => {
                    return this.cartRepository.post<CartModel>(
                        response,
                        {name: 'ADD_PRODUCT', queryParams: {idc: this.cart.id, type: 'show'}},
                        this.isAuth()
                    )
                }),
                takeUntil(this.unsubscribe$)
            )
                .subscribe((cart: CartModel | null) => {
                    this.cart = cart || new CartModel();
                    this.parseCart();
                })
        }
    }

    changeQtyGift(gift: GiftboxModel, qty: number = 1) {
        const row = this.cart.rows
            .find(row => row.ref === gift.id);

        if (isBlank(row)) {
            this.addGift(gift, qty);
        } else {
            this._changeQtyGift(row, qty);
        }
    }

    addGift(gift: GiftboxModel, qty: number = 1) {
        if (isBlank(this.cartId)) {
            this.createCart();
            this.callCart$.pipe(
                filter(value => !value),
                switchMap((_) =>
                    this.cartRepository.post<CartModel>(
                        {box: gift.id, qty: qty},
                        {name: 'ADD_PRODUCT', queryParams: {idc: this.cart.id, type: 'gift-box'}},
                        this.isAuth()
                    )
                ),
                takeUntil(this.unsubscribe$)
            ).subscribe((cart) => {
                this.cart = cart || new CartModel();
                this.parseCart();
            })
        } else {
            this.cartRepository.post<CartModel>(
                {box: gift.id, qty: qty},
                {name: 'ADD_PRODUCT', queryParams: {idc: this.cart.id, type: 'gift-box'}},
                this.isAuth()
            ).pipe(takeUntil(this.unsubscribe$))
                .subscribe((cart: CartModel | null) => {
                    this.cart = cart || new CartModel();
                    this.parseCart();
                })
        }
    }

    removeShow(session: SessionModel, seatGroup: GroupModel, price: PriceModel, qty: number = 1) {
        const rows = this.cart.rows
            .filter((row) => row.type === 'show')
            .filter(
                (row) => row.data.session.id === session.id && row.data.price.id === price.id && row.data.group.id === seatGroup.id
            );
        const toDelete = [];
        for (let i = 0; i < qty; ++i) {
            if (!isBlank(rows[i])) {
                toDelete.push(
                    this.rowRepository.remove<RowModel>(
                        rows[i],
                        {name: 'BASE', queryParams: {idc: this.cart.id}},
                        this.isAuth()
                    )
                )
            }
        }
        concat(...toDelete)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                complete: () => this.refreshCart().pipe(takeUntil(this.unsubscribe$)).subscribe()
            });
    }

    parseCart() {
        this.cartSummary = {};
        this.nbInCart = 0;
        this.cart.rows.map((row) => {
            let key = row.type === 'show' ?
                row.data.session.id + '_' + row.data.group.id + '_' + row.data.price.id :
                'gift_' + row.ref;
            if (isBlank(this.cartSummary[key])) {
                const summary = new CartSummaryModel();
                summary.key = key;
                summary.type = row.type;
                summary.data = row.data;
                if (row.type === 'show') {
                    summary.date = row.data.session.date;
                    summary.group = row.data.group.name;
                    summary.productName = row.data.show.name;
                } else {
                    summary.productName = row.productName;
                }
                summary.price = row.totalIncTax;
                this.cartSummary[key] = summary;
            } else {
                this.cartSummary[key].price += row.totalIncTax;
            }
            if (!isBlank(row.code)) {
                this.cartSummary[key].codes.push({rowId: row.id, code: row.code?.code || ''});
            }
            if (!isBlank(row.data.price?.group?.requiredSubscription) && row.data.price.group.requiredSubscription) {
                if (!isBlank(row.subscription)) {
                    this.cartSummary[key].subscription.push({code: row.subscription.code, rowId: row.id});
                } else {
                    this.cartSummary[key].subscription.push({code: null, rowId: row.id});
                }
            }
            this.cartSummary[key].qty += row.qty;
            this.nbInCart += row.qty;
        });
        this.callCartParsedSubject.next(true);
    }

    pay(type: string) {
        return this.callCart$.pipe(
            filter(value => !value),
            switchMap(_ => this.orderRepository
                .post<OrderModel>(null, {name: 'CART_TO_ORDER', queryParams: {idc: this.cart?.id}})),
            switchMap(order => {
                this.currentOrderId = order!.id;
                return this.orderRepository.findOneBy(
                    null,
                    {name: 'PAY', queryParams: {ido: order?.id, type: type}},
                    true,
                    true
                )
            }),
            tap(_ => {
                this.cartId = '';
                this.cart = new CartModel();
                this.parseCart();
            }),
            takeUntil(this.unsubscribe$)
        )
    }

    applySubscription(rowId: string, code: string): Observable<CartModel> {
        return this.rowRepository.put<CartModel>(
            {code: code},
            {name: 'SUBSCRIPTION', queryParams: {idc: this.cartId, idr: rowId}},
            this.isAuth(),
            true
        )
            .pipe(
                switchMap(response => {
                    return of(this.cartRepository.populate<CartModel>(CartModel, response!)!)
                }),
                takeUntil(this.unsubscribe$)
            )
    }

    removeSubscription(rowId: string) {
        return this.rowRepository.delete(
            {name: 'SUBSCRIPTION', queryParams: {idc: this.cartId, idr: rowId}},
            null,
            this.isAuth()
        )
            .pipe(
                takeUntil(this.unsubscribe$)
            )
    }

    applyCode(code: string) {
        this.cartRepository.put<CartModel>(
            {code: code},
            {name: 'ADD_CODE', queryParams: {idc: this.cart.id}},
            this.isAuth()
        ).pipe(takeUntil(this.unsubscribe$))
            .subscribe((cart: CartModel | null) => {
                this.cart = cart || new CartModel();
                this.parseCart();
            })
    }

    removeCode(rowId: string) {
        this.cartRepository.delete<CartModel>(
            {name: 'DEL_CODE', queryParams: {idc: this.cart.id, idr: rowId}},
            null,
            this.isAuth()
        )
            .pipe(
                switchMap(_ => this.refreshCart()),
                takeUntil(this.unsubscribe$)
            )
            .subscribe()
    }

    clear(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
        this.unsubscribe$ = new Subject<void>();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private isAuth() {
        return !isBlank(this.authService.currentUser);
    }

    private _changeQtyGift(row: RowModel, qty: number = 1) {
        this.cartRepository.put<CartModel>(
            {qty: qty},
            {name: 'EDIT_ROW', queryParams: {idc: this.cart.id, idr: row.id}},
            this.isAuth()
        ).pipe(takeUntil(this.unsubscribe$))
            .subscribe((cart) => {
                this.cart = cart || new CartModel();
                this.parseCart();
            })
    }

}
