import {Always, Api, ApiRoute, Column, ToInt} from "../decorators/api.decorator";
import {environment} from "../../../../environments/environment";
import {AccountModel} from "./account.model";
import {PlaceResponseModel} from "./place.response.model";

@Api(
    {
        basePath: "theaters",
        baseURL: environment.theater_path
    }
)
@ApiRoute('CURRENT', '/theaters/current')
export class TheaterModel {
    @Column()
    id: string = '';
    @Column('account', AccountModel)
    account: AccountModel | null = null;
    @Column()
    name: string = '';
    @Always()
    @Column('address', PlaceResponseModel)
    address: PlaceResponseModel | null = null;
    @Column()
    @ToInt()
    nbPlaces: number = 0;
}
