import {Column, Ignore} from "../../decorators/api.decorator";

export enum PaymentTypes {
    cash = 'Espèce',
    check = 'Chèque',
    cb = 'CB',
    transfer = 'virement bancaire',
    payfip = 'Payfip'
}

export class PaymentModel {
    @Column()
    id: string = '';
    @Column()
    type: string = '';
    @Column()
    status: string = '';
    @Column()
    transactionId: string = '';
    @Column()
    info: any = {};
    @Column()
    amount: number = 0;

    @Ignore()
    get paymentTypeTranslation(): string {
        // @ts-ignore
        return this.type ? PaymentTypes[this.type] : '';
    }
}
