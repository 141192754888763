import {RepositoryService} from "../../services/repository.service";
import {MenuModel} from "../models/menu.model";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class MenuRepository extends RepositoryService<MenuModel> {

    constructor(client: HttpClient) {
        super(client, MenuModel);
    }
}
