import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {MenuModel} from 'src/app/_common/shared/models/menu.model';
import {MenuRepository} from '../../../_common/shared/repository/menu.repository';
import {TheaterService} from '../../../_common/services/theater.service';

@Component({
    selector: 'app-footer, [app-footer]',
    templateUrl: './footer.component.html',
    styles: [`
        :host {
            display: contents;
        }
    `]
})
export class FooterComponent implements OnInit, OnDestroy {

    public menuBottom: MenuModel = new MenuModel();
    public today = new Date();
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        public readonly theaterService: TheaterService,
        private readonly menuRepository: MenuRepository,
    ) {
    }

    ngOnInit(): void {
        this.menuRepository.findOneBy<MenuModel>(
            null,
            {
                name: 'BY_SLUG',
                queryParams: {slug: 'menu-bottom'}
            },
            false
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(menuBottom => {
                this.menuBottom = menuBottom || new MenuModel();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }
}
