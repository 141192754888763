import {Api, ApiRoute, Column, Ignore} from "../decorators/api.decorator";
import {AccountModel} from "./account.model";
import {environment} from "../../../../environments/environment";
import {SeoModel} from "./seo.model";

@Api({
    basePath: 'categories',
    baseURL: environment.theater_path
})
@ApiRoute('PUBLIC', '/public/categories')
export class CategoryModel {
    @Column()
    id: string = '';
    @Ignore()
    @Column('account', AccountModel)
    account: AccountModel | null = null;
    @Column()
    name: string = '';

    seo: SeoModel = new SeoModel();
}
