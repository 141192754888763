export class CartSummaryModel {
    key: string = '';
    codes: { rowId: string, code: string }[] = [];
    group: string = '';
    productName: string = '';
    price: number = 0;
    date: string = '';
    qty: number = 0;
    type: string = '';
    data: any = {};
    subscription: { code: string|null, rowId: string }[] = [];
}

