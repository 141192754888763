import {InjectionToken} from "@angular/core";

declare global {
  interface Window {
    paygreenjs: any;
  }
}

export const PAYGREEN_OPTIONS = new InjectionToken('PAYGREEN_OPTIONS');

export enum PaygreenMode {
  recette,
  prod,
  sandbox
}

export interface PaygreenConfig {
  mode: PaygreenMode;
  publicKey: string;
}

export interface PaymentOrder {
  id: string;
  secret: string;
}
