import {Injectable} from "@angular/core";
import {TheaterRepository} from "../shared/repository/theater.repository";
import {TheaterModel} from "../shared/models/theater.model";
import {Observable, Subject, takeUntil, tap} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class TheaterService {

    public currentTheater: TheaterModel;
    private unsubscribe$: Subject<void> = new Subject<void>();

    public constructor(
        private theaterRepository: TheaterRepository
    ) {
    }

    public load(): Observable<TheaterModel | null> {
        return this.theaterRepository.findOneBy<TheaterModel>(null, {name: 'CURRENT', queryParams: {}}, false)
            .pipe(
                tap(theater => this.currentTheater = theater!),
                takeUntil(this.unsubscribe$)
            )
    }
}
